<template>
  <div class="flow-title">
    <canvas ref="canvas" @click="clickText"></canvas>
  </div>
</template>
<script>
export default {
  data () {
    return {
      timer: null,
      canvas: null,
      ctx: null,
      index: -50,
      flag: 1,
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    },
    color1: {
      type: String,
      default: 'rgba(0,255,194, 1)'
    },
    color2: {
      type: String,
      default: 'rgba(162,70,255, 1)'
    }
  },
  mounted () {
    this.init()
    this.timer = setInterval(() => {
      this.drawText()
    }, 20)
  },
  methods: {
    init() {
      this.canvas = this.$refs.canvas
      this.canvas.height = this.size*1.1
      this.ctx = this.canvas.getContext('2d')
      this.changeLength()
      this.drawText()
    },
    setGradient () {
      if (this.index > 100) {
        this.index = -50
        this.flag++
        if (this.flag > 2) {
          this.flag = 1
        }
      }
      this.index ++
      let sc = this.index / 100
      let ec = sc + 0.5
      let gradient = this.ctx.createLinearGradient(this.width*sc,0,this.width*ec, this.canvas.height)
      gradient.addColorStop('0', this.flag==1?this.color1:this.color2)
      gradient.addColorStop('1', this.flag==1?this.color2:this.color1)
      return gradient
    },
    drawText () {
      this.ctx.clearRect(0,0,this.width, this.canvas.height)
      this.ctx.font = `Bold ${this.size}px '方圆'`
      this.ctx.fillStyle = this.setGradient()
      this.ctx.fillText(this.title, 0, this.size*0.85)
    },
    changeLength () {
      this.ctx.font = `Bold ${this.size}px '方圆'`
      this.width = this.ctx.measureText(this.title).width
      this.canvas.width = this.width
    },
    clickText () {
      this.$emit('click')
    }
  },
  watch: {
    title () {
      this.changeLength()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped lang="stylus">
</style>