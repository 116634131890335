<template>
  <div class="cx-lang" @click.stop="clickChangeLang()">
    <p class="lang cn" :class="{active: language === 'CN'}">中</p>
    <p class="lang en" :class="{active: language === 'EN'}">EN</p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      change: false,
    }
  },
  created () {
    if (this.storage && this.storage.save_language) {
      let lang = this.storage.save_language
      this.setLang(lang)
    }
    this.getData()
  },
  methods: {
    getData () {
    },
    clickChangeLang () {  
      let lang = ''
      if (this.language === 'CN') {
        lang = 'EN'
      } else {
        lang = 'CN'
      }
      this.setLang(lang)
      this.change = true
      setTimeout(() => {
        this.change = false
      }, 200);
    },
    setLang (lang) {
      this.$store.commit('set_language', lang)  
      this.$i18n.locale = lang   
      this.saveStorage({key: 'save_language', val: lang})
    },
  },
}
</script>
<style scoped lang="stylus">
.cx-lang
  display flex
  cursor pointer
  .lang
    width 30px
    height 30px
    line-height 30px
    border-radius 50%
    text-align center
    font-size 15px
    color #000
    fontWeight()
    background-color #8B8B8B
  .en
    margin-left -8px
  .active
    background-color $c8
    z-index 3
@media screen and (max-width: 800px)
  .cx-lang
    .lang
      font-size 0.5rem
      width 0.9rem
      height 0.9rem
      line-height 0.9rem
@media screen and (max-width: 600px)
  .cx-lang
    .lang
      font-size 0.7rem
      width 1.2rem
      height 1.2rem
      line-height 1.2rem
</style>