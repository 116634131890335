<template>
  <div class="cx-404">
    <div class="logo"><img src="@assets/images/logo.svg" alt="晨兴可视"></div>
    <lang class="headLang"></lang>
    <p class="title1">
      <label class="text">404</label>
    </p>
    <p class="title2">{{$t('contact.contact1')}}</p>
    <flow-title class="flowTitle1" :title="$t('contact.back')" :size="18" @click="clickBackHome"></flow-title>
  </div>
</template>
<script>
import flowTitle from '@/components/base/flow-title.vue'
import lang from '@/components/base/lang.vue'
export default {
  data () {
    return {
    }
  },
  props: {
    normal: {
      type: String,
      default: '1.png'
    },
    active: {
      type: String,
      default: '1-1.png'
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickBackHome () {
      this.$router.push('/home')
    }
  },
  components: {flowTitle, lang}
}
</script>
<style scoped lang="stylus">
.cx-404
  text-align center
  min-height 100vh
  display flex
  justify-content center
  flex-direction column
  background-color #000011
  .title1
    margin-bottom 24px
    height 80px
    .text
      font-size 80px
      fontWeight()
      position absolute
      left 50%
      transform translate3d(-50%, 0, 0)
      color transparent
      background-image linear-gradient(-45deg, rgba(162,70,255, 1), rgba(0,255,194, 1) )
      -webkit-background-clip text
      background-clip text
  .title2
    color $c7
    font-size 24px
    margin-bottom 24px
  .flowTitle1
    margin-bottom 200px
    cursor pointer
  .headLang, .logo
    position absolute
    top 0
    margin 22px 40px 0 0
    z-index 10
  .headLang
    right 0
  .logo
    left 0
    margin 22px 0 0 40px
</style>